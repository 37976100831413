@font-face {
  font-family: "wancore-header";
  src: url("/public/fonts/elevate-v-bold.woff2");
}
@font-face {
  font-family: "wancore";
  src: url("/public/fonts/elevate-v45.woff2");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  color: #555 !important;
}
html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: "wancore";
  font-size: 17px !important;
}

/* ========================================================
  ==========================================================
              CSS ROOT
  ==========================================================
  ==========================================================
 */
:root {
  --grad-1: linear-gradient(145deg, #faf9c5 40%, #e8f9ff 90%);
  --grad-2: linear-gradient(35deg, #faf9c5 40%, #e8f9ff 90%);
  --grad-3: linear-gradient(35deg, #fcfce2 40%, #f5fbfe 90%);
  --grad-4: linear-gradient(145deg, #f3fcff 40%, #d3fff5 90%);
  --grad-5: linear-gradient(to top right, #f29727, #ffb527);
  --size-12: 0.75rem;
  --size-13: 0.8125rem;
  --size-14: 0.875rem;
  --size-16: 1rem;
  --size-17: 1.0625rem;
  --size-18: 1.125rem;
  --size-22: 1.375rem;
  --size-24: 1.5rem;
  --size-28: 1.75rem;
  --size-32: 2rem;
  --size-40: 2.5rem;
  --size-55: 3.4375rem;
  --size-70: 4.375rem;
  --color-grey-0: #292d34;
  --color-grey-1: #7c828d;
  --color-grey-2: #b9bec7;
  --color-grey-3: #e9ebf0;
  --color-grey-4: #fafbfc;
  --color-white: #fff;
  --color-black: #000;
  --color-pink: #eb3dae37;
  --color-pink-dark: #de1798;
  --color-red-light: #fd71713b;
  --color-red-light: #fd717139;
  --color-red: #fd7171;
  --color-blue: #5bc5fa;
  --color-cyan: #49ccf9;
  --color-green-light: #6ac95042;
  --color-green: #6bc950;
  --color-teal: #5cc0bf;
  --color-teal-light: #8afcde;
  --color-yellow: #f7ce51;
  --color-yellow-dark: #f5cd51;
  --color-brand: #7b68ee;
  --color-purple: #7b42d112;
  --color-purple-dark: #8041d0;
  --color-background: #fafbfc;
  --color-brand-facebook-light: #3b589842;
  --color-brand-facebook: #3b5998;
  --color-brand-instagram-light: #c32aa233;
  --color-brand-instagram: #c32aa3;
  --color-brand-linkedin: #0077b5;
  --color-brand-twitter: #1da1f2;
  --color-brand-youtube-light: rgba(255, 0, 0, 0.246);
  --color-brand-youtube: rgba(255, 0, 0, 0.898);
  --color-success: var(--color-green);
  --color-error: var(--color-red);
  --bg-main: var(--color-grey-4);
  --bg-secondary: var(--color-white);
  --easing-natural: cubic-bezier(0.5, 0, 0.5, 1);
  --easing-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --easing-in: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --easing-out: cubic-bezier(0.165, 0.84, 0.44, 1);
  --easing-in-out: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
/* ========================================================
  ==========================================================
              Material UI Theme  
  ==========================================================
  ==========================================================
 */
.MuiButtonBase-root {
  text-transform: capitalize !important;
  font-size: 15px !important;
  border-radius: 48px !important;
}
.MuiButtonBase-root-navbar:hover {
  background: rgba(225, 225, 225, 0.3) !important;
}

/* ========================================================
  ==========================================================
              UTILITIES AND CUSTOM STYLES  
  ==========================================================
  ==========================================================
 */
.place--center {
  display: grid;
  place-content: center;
  text-align: center;
}
.use-header-font {
  font-family: "wancore-header" !important;
}
.dropdown {
  position: absolute !important;
  top: 0px !important;
  left: 60px;
  visibility: hidden;
  opacity: 0;
  background: white !important;
  transition: 300ms ease-in-out !important;
}
.active-dropdown {
  visibility: visible;
  opacity: 1;
  top: 60px !important;
  z-index: -3 !important;
}
.increase-font-size {
  font-size: 18px !important;
}
.btn--extended {
  width: 240px !important;
  height: 60px !important;
  font-family: "wancore-header" !important;
  font-size: 18px !important;
}
/* ========================================================
  ==========================================================
              ELEMENT STYLES
  ==========================================================
  ==========================================================
 */
.banner--slider {
  width: 100%;
  height: 100vh !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.banner--slider::before {
  content: "";
  background: indigo;
  position: absolute;
  width: 100%;
  opacity: 0.4;
  top: 0;
  left: 0;
  bottom: 0;
}
.banner--caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 900;
}
.banner--caption h2 {
  line-height: 70px !important;
  color: #fff;
}
.enrolment--card {
  width: 100%;
  background: url("./assets/images/svgBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  display: grid;
  place-content: center;
  border-radius: 30px;
  padding: 0px 30px;
}
.about--container {
  width: 100%;
  height: 130vh;
  padding: 80px 200px;
  overflow: hidden;
  background: url("./assets/images/Bg\ illustration.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* ======================================================================
 ============================= LINE BG ========================
  */
.line--bg {
  width: 100%;
  padding: 80px 200px;
  overflow: hidden;
  background: url("./assets/images/Bg\ illustration.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* ========================================================
=============================================================
ABOUT STYLES
===============================================================
===================================================================
===== */
.gradient-bg {
  width: 100%;
  background: var(--grad-3);
}
.wancore--img {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
/* ========================================================
  ==========================================================
              UTILITIES AND CUSTOM STYLES  
  ==========================================================
  ==========================================================
 */
a {
  text-decoration: none;
}
.background--image {
  width: 100% !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: inherit;
  position: relative;
}
.background--image::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #041e3c;
  position: absolute;
  top: 0;
  opacity: 0.8;
  left: 0;
}
.transform-box {
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
}
.rescue {
  z-index: 10000;
  position: relative;
}
.nav-a {
  transition: 300ms ease-in-out;
}
.nav-a:hover {
  color: var(--coral);
}
.place--center {
  display: grid;
  place-content: center;
  text-align: center;
}
.place--between {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.align--center {
  align-items: center;
}
.use-header-font {
  font-family: "wancore-header" !important;
}
.increase-font-size {
  font-size: 18px !important;
}
.btn--extended {
  width: 240px !important;
  height: 60px !important;
  font-family: "wancore-header" !important;
  font-size: 18px !important;
}
.box-container {
  width: 80% !important;
  margin: 0 auto !important;
}
.controlled--text {
  max-width: 80%;
}
.gallery {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.gallery--caption {
  display: grid;
  place-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  transform: translateX(-120px);
  transition: 300ms var(--ui-anim-func);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  visibility: hidden;
  opacity: 0;
  background-color: var(--coral);
}

.gallery:hover > .gallery--caption {
  visibility: visible !important;
  transform: translateX(0px) !important;
  cursor: pointer;
  opacity: 0.8 !important
  ;
}
/* 
.dropdown {
  position: absolute !important;
  top: 0px !important;
  left: 60px;
  visibility: hidden;
  opacity: 0;
  background: white !important;
  transition: 300ms ease-in-out !important;
}
.active-dropdown {
  visibility: visible;
  opacity: 1;
  top: 60px !important;
  z-index: -3 !important;
} */

/* ========================================================
  ==========================================================
              SWIPER JS STYLES
  ==========================================================
  ==========================================================
 */
.swiper-button-next,
.swiper-button-prev {
  background: var(--blue-10) !important;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  color: #fff !important;
}
.swiper-pagination .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #fff !important;
  transition: var(--ui-anim-func) !important;
  opacity: 1 !important;
}
.swiper-pagination .swiper-pagination-bullet-active {
  height: 25px !important;
  border-radius: 10px !important;
  background: var(--coral) !important;
}

@media screen and (max-width: 620px) {
  .banner--caption h2 {
    line-height: 50px !important;
    width: 100%;
    font-size: 45px !important;
  }
  .about--container {
    width: 100%;
    height: 130vh;
    padding: 80px 10px;
  }
  .hidden--image {
    width: 340px !important;
  }
  .box-container {
    width: 100%;
  }
  .controlled--text {
    max-width: 100%;
  }
  h2 {
    font-size: 38px !important;
  }
  h3 {
    font-size: 35px !important;
  }
  .transform-box {
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 65px !important;
    height: 65px !important;
    opacity: 0.8;
  }
  .MuiTypography-h6 {
    font-size: 18px !important;
  }
}

/* =================================================================================  
  Gallery
  =================================================================================*/
.gallery-place-holder::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  background: rgba(225, 225, 225, 0.85);
  opacity: 0;
  visibility: hidden;
  border: 0;
}
.gallery-caption {
  position: relative;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
  color: var(--color-red);
}
.gallery-place-holder {
  position: relative;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}
.gallery-place-holder:hover {
  border: 6px solid var(--color-purple-dark);
}
.gallery-place-holder:hover::before {
  visibility: visible;
  opacity: 1;
}
.gallery-place-holder:hover > .gallery-caption {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}
/* ========================================================
  ==========================================================
              SWIPER JS STYLES
  ==========================================================
  ==========================================================
 */
.swiper-button-next,
.swiper-button-prev {
  background: var(--blue-10) !important;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  color: #fff !important;
}
.swiper-pagination .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #fff !important;
  transition: var(--ui-anim-func) !important;
  opacity: 1 !important;
}
.swiper-pagination .swiper-pagination-bullet-active {
  height: 25px !important;
  border-radius: 10px !important;
  background: var(--color-brand-facebook) !important;
}
.animated-btn {
  animation: bounce 3000ms ease-in infinite;
}
.animated-btn:hover {
  animation: goCrazy 300ms ease infinite;
}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes goCrazy {
  0% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.training-span {
  /* display: block; */
  padding: 3px;
  border-radius: 100px;
  display: block;
  background: #f25d50;
}

@media screen and (max-width: 600px) {
  .training-span {
    background-color: transparent;
    border-radius: 0px;
    padding: 10px 0px;
    color: rgba(225, 225, 225, 90);
  }
  .animated-btn {
    animation: goCrazy 500ms ease infinite;
    margin-bottom: 30px !important;
  }
}
